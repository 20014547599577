// @ts-strict-ignore
import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styled, { css } from 'styled-components';
import { capitalize } from 'underscore.string';
import Hub from 'src/components/siteBar/icons/hub';
import { useSelector } from 'src/store/store';
import MobileMoreMenu from 'src/components/siteBar/mobileMoreMenu';
import i18n from 'src/utils/translate';
import { Section } from 'src/constants/footfall';
import { footfall } from 'src/components/siteBar/sitebarFootfall';
import { Counter, ImageContainer, LinkText, BottomSiteBar, StyledLink, LinkStyles } from 'src/components/siteBar/sharedStyles';
import PaperPlane from 'src/components/siteBar/icons/paperPlane';
import Bell from 'src/components/siteBar/icons/bell';
import Menu from 'src/components/siteBar/icons/menu';
import { useBranch } from 'src/utils/cookie';
import Pen from 'src/components/siteBar/icons/pen';
import { WriteLink } from 'src/modules/community/shared/components/writeButton';

export default function MobileSiteBarBottom(): JSX.Element {
  const { asPath } = useRouter();
  const route = asPath.split('/')[1];
  const unreadMessagesCount = useSelector(({ context }) => context?.unreadMessagesCount) ?? 0;
  const unreadNotificationsCount = useSelector(({ context }) => context.notifications?.unreadNotificationsCount) ?? 0;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const menuButtonRef = useRef(null);

  const isB = useBranch() === 'B';

  const handleClickOutside = (event) => {
    if (!menuRef?.current?.contains(event.target)) setIsMenuOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []); // eslint-disable-line

  return (
    <div ref={menuRef}>
      <BottomContainer>
        <Link
          legacyBehavior
          passHref
          as="/my-hub"
          href="/myHub"
        >
          <StyledLink
            $isActive={'my-hub' === route}
            $isB={isB}
            onClick={() => {
              footfall(Section.MyHub);
              setIsMenuOpen(false);
            }}
          >
            <ImageContainer>
              <Hub />
            </ImageContainer>
            <LinkText $isActive={'my-hub' === route}>{capitalize(i18n.t('my hub'))}</LinkText>
          </StyledLink>
        </Link>

        <Link
          legacyBehavior
          passHref
          as="/messages"
          href="/messages"
        >
          <StyledLink
            $isActive={'messages' === route}
            $isB={isB}
            onClick={() => {
              footfall(Section.Chat);
              setIsMenuOpen(false);
            }}
          >
            <ImageContainer>
              <PaperPlane />
              {unreadMessagesCount > 0 && <Counter data-testid="chat-message-number">{unreadMessagesCount}</Counter>}
            </ImageContainer>
            <LinkText $isActive={'messages' === route}>{capitalize(i18n.t('chat'))}</LinkText>
          </StyledLink>
        </Link>

        {isB ? (
          <StyledWriteLink $isActive={asPath.includes('write')} onClick={() => footfall(Section.Post)}>
            <ImageContainer>
              <Pen />
            </ImageContainer>
            <LinkText $isActive={asPath.includes('write')}>{capitalize(i18n.t('post'))}</LinkText>
          </StyledWriteLink>
        ) : (
          <Link
            legacyBehavior
            passHref
            as="/write"
            href="/community/write"
          >
            <StyledLink $isActive={asPath.includes('write')} onClick={() => footfall(Section.Post)}>
              <ImageContainer>
                <Pen />
              </ImageContainer>
              <LinkText $isActive={asPath.includes('write')}>{capitalize(i18n.t('post'))}</LinkText>
            </StyledLink>
          </Link>
        )}

        <Link
          legacyBehavior
          passHref
          as="/notifications"
          href="/notifications"
        >
          <StyledLink
            $isActive={'notifications' === route}
            $isB={isB}
            onClick={() => {
              footfall(Section.Alerts);
              setIsMenuOpen(false);
            }}
          >
            <ImageContainer>
              <Bell />
              {unreadNotificationsCount > 0 && <Counter>{unreadNotificationsCount}</Counter>}
            </ImageContainer>
            <LinkText $isActive={'notifications' === route}>{capitalize(i18n.t('alerts'))}</LinkText>
          </StyledLink>
        </Link>

        <StyledLink
          $isActive={isMenuOpen}
          $isB={isB}
          href="#"
          ref={menuButtonRef}
          onClick={(e) => {
            e.preventDefault();
            setIsMenuOpen(!isMenuOpen);
            menuButtonRef?.current?.blur();
            footfall(Section.More);
          }}
        >
          <ImageContainer>
            <Menu />
          </ImageContainer>
          <LinkText $isActive={isMenuOpen}>{capitalize(i18n.t('menu'))}</LinkText>
        </StyledLink>
      </BottomContainer>

      <MobileMoreMenu isOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
    </div>
  );
}

const BottomContainer = styled(BottomSiteBar)`
  padding-bottom: env(safe-area-inset-bottom);
  height: calc(${({ theme }) => theme.navHeightTop} + env(safe-area-inset-bottom));
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  ${StyledLink} {
    width: 100%;
  }
`;

const StyledWriteLink = styled(WriteLink)<{ $isActive: boolean }>`
  ${LinkStyles}
  width: calc(${({ theme }) => theme.navHeightTop} + 20px);
  height: calc(${({ theme }) => theme.navHeightTop} + 20px);
  margin: -20px 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;
  background-color: #CAE3F9;
  color: #0F4F86;
  ${LinkText} {
    color: #0F4F86;
  }
  &:focus, &:hover, &:active {
    background-color: #7AB9F0;
    color: #092D4D;
    ${LinkText} {
      color: #092D4D;
    }
  }
  ${({ $isActive }) => $isActive && css`
    background-color: ${({ theme }) => theme.colorBlueBackground};
    color: ${({ theme }) => theme.colorGreyDark};
    &:focus, &:hover, &:active,
    ${LinkText},
    &:hover ${LinkText} {
      background-color: ${({ theme }) => theme.colorBlueBackground};
      color: ${({ theme }) => theme.colorGreyDark};
    }
  `}
`;
